@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-panel {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.09);
  border-radius: 1rem;
  overflow: hidden;
  padding: 2.125rem 1.875rem;

  &:hover::before {
    opacity: 1;
  }

  &:before {
    border-radius: inherit;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 350ms;
    width: 100%;
    pointer-events: none;
    background: radial-gradient(
      1000px circle at var(--mouse-x) var(--mouse-y),
      rgba(255, 255, 255, 0.06),
      transparent 40%
    );
    z-index: 3;
  }

  &--colspan-1 {
    grid-column: span 1;
  }

  &--clickable {
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.15s ease;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      background: linear-gradient(
          135.91deg,
          var(--ac-color-primary) -7.52%,
          rgba(255, 236, 63, 0) 98.92%
        ),
        rgba(255, 255, 255, 0.06);
      opacity: 0;
      z-index: -1;
      transition: opacity 0.25s ease;
    }

    &:hover {
      transform: scale(1.02);
      &::after {
        opacity: 0.4;
      }
    }
  }

  &--full-height {
    height: 100%;
  }
}
