@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-text-button {
  display: inline-block;
  cursor: pointer;

  &-icon {
    margin-right: var(--ac-pixel-size-14);
    path {
      fill: var(--ac-color-primary);
    }
  }

  &-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-label {
    display: inline-block;
    font-size: var(--ac-typography-font-size-16);
    font-weight: 600;
    line-height: var(--ac-typography-line-height-140);
    transition: transform 0.35s ease;

    &--main {
      transform: translateY(0);
    }

    &--secondary {
      position: absolute;
      left: 0;
      transform: translateY(100%);
    }

    .ac-text-button--light & {
      color: var(--ac-color-neutral-00);
    }

    .ac-text-button--dark & {
      color: var(--ac-color-black);
    }

    &-wrapper {
      overflow: hidden;
      position: relative;
      border-bottom: 1px solid var(--ac-color-primary);
    }
  }

  &:hover {
    .ac-text-button-label--main {
      transform: translateY(-100%);
    }

    .ac-text-button-label--secondary {
      transform: translateY(0%);
    }
  }
}
