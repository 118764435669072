@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-panel {
    &__header {
    }

    &__pre-title {
        font-family: 'inter', sans-serif;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        font-size: 1em;
        margin: 0 0 0.5em;
    }

    &__title {
        font-family: 'Sora', sans-serif;
        font-weight: 700;
        color: #fff;
        font-size: 1.25em;
    }
}
