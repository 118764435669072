//General font-size mixin. pass css variable as size
@mixin font-size($size) {
  font-size: $size;
}

//Mixin to include body font
@mixin body-font() {
  font-family: 'inter', sans-serif;
  font-weight: var(--ac-typography-font-weight-regular);
}

//Mixin to include title font
@mixin title-font() {
  font-family: 'Sora', sans-serif;
  font-weight: 700;
}

@mixin define-color($title, $color) {
  #{$title}: #{$color};
  #{$title}-rgb: #{red($color)}, #{green($color)}, #{blue($color)};
}

@mixin button-transitions() {
  transition: color 0.2s ease, background-color 0.15s ease-out, fill 0.2s ease,
    box-shadow 0.2s ease;
}

// RESPONSIVE

@mixin for-tablet-and-up {
  @media screen and (min-width: 769px) {
    @content;
  }
}

@mixin for-desktop-sm-and-up {
  @media screen and (min-width: 1281px) {
    @content;
  }
}
@mixin for-desktop-md-and-up {
  @media screen and (min-width: 1441px) {
    @content;
  }
}
@mixin for-desktop-lg-and-up {
  @media screen and (min-width: 1921px) {
    @content;
  }
}

@function use-alpha($title, $alpha: 1) {
  @return rgba(var(#{$title}-rgb), $alpha);
}
