@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-container {
  @include for-desktop-lg-and-up {
    max-width: 1920px;
    margin: 0 auto;
  }
  &-padding {
    &-none {
      padding: 0;
    }

    &-default {
      padding: 1.5rem;
    }
  }
}
