:root {
  /* COLORS */
  --ac-body-background-color: #01010b;
  --ac-body-color: #fff;
  @include define-color('--ac-color-transparent', #00000000);
  @include define-color('--ac-color-black', #000);
  @include define-color('--ac-color-white', #ffffff);
  @include define-color('--ac-color-neutral-00', #ffffff);
  @include define-color('--ac-color-neutral-50', #f9fafa);
  @include define-color('--ac-color-neutral-100', #e6e6e7);
  @include define-color('--ac-color-neutral-200', #b3b3b6);
  @include define-color('--ac-color-neutral-300', #99999d);
  @include define-color('--ac-color-neutral-400', #4d4d54);
  @include define-color('--ac-color-neutral-500', #01010b);

  @include define-color('--ac-color-primary', #ffec3f);
  @include define-color('--ac-color-primary--dark', #3d3c1a);
  @include define-color('--ac-color-secondary', #fa06ff);
  @include define-color('--ac-color-secondary--dark', #3b2141);
  @include define-color('--ac-color-tertiary', #80ffb5);
  @include define-color('--ac-color-tertiary--dark', #233a3e);

  @include define-color('--ac-color-menu-background', #333);
  
  @include define-color('--ac-color-menu-hover', rgba(256, 256, 256, 0.16));
  --ac-color-gradient-button: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 236, 63, 0.4) 0%,
    rgba(255, 236, 63, 0) 100%
  );

  --ac-color-gradient-primary: linear-gradient(
      135.91deg,
      rgba(255, 236, 63, 0.68) -7.52%,
      rgba(255, 236, 63, 0) 98.92%
    ),
    rgba(255, 255, 255, 0.06);
  --ac-color-gradient-primary-light: linear-gradient(
      135.69deg,
      rgba(255, 236, 63, 0.4) -16.31%,
      rgba(255, 236, 63, 0) 71.66%
    ),
    rgba(255, 255, 255, 0.06);

  /* TYPOGRAPHY */
  --ac-typography-line-height-120: 1.2;
  --ac-typography-line-height-125: 1.25;
  --ac-typography-line-height-140: 1.4;
  --ac-typography-line-height-150: 1.5;
  --ac-typography-line-height-160: 1.6;
  --ac-typography-line-height-170: 1.7;

  --ac-typography-font-weight-light: 300;
  --ac-typography-font-weight-regular: 400;
  --ac-typography-font-weight-semibold: 600;
  --ac-typography-font-weight-bold: 700;

  --ac-typography-font-size-12: var(--ac-pixel-size-12);
  --ac-typography-font-size-14: var(--ac-pixel-size-14);
  --ac-typography-font-size-16: var(--ac-pixel-size-16);

  /* SIZING */

  --ac-pixel-size-1: 0.0625rem;
  --ac-pixel-size-2: 0.125rem;
  --ac-pixel-size-3: 0.1875rem;
  --ac-pixel-size-4: 0.25rem;
  --ac-pixel-size-5: 0.3125rem;
  --ac-pixel-size-6: 0.375rem;
  --ac-pixel-size-7: 0.4375rem;
  --ac-pixel-size-8: 0.5rem;
  --ac-pixel-size-9: 0.5625rem;
  --ac-pixel-size-10: 0.625rem;
  --ac-pixel-size-12: 0.75rem;
  --ac-pixel-size-13: 0.8125rem;
  --ac-pixel-size-14: 0.875rem;
  --ac-pixel-size-16: 1rem;
  --ac-pixel-size-18: 1.125rem;
  --ac-pixel-size-20: 1.25rem;
  --ac-pixel-size-22: 1.375rem;
  --ac-pixel-size-24: 1.5rem;
  --ac-pixel-size-26: 1.625rem;
  --ac-pixel-size-28: 1.75rem;
  --ac-pixel-size-30: 1.875rem;
  --ac-pixel-size-32: 2rem;
  --ac-pixel-size-36: 2.25rem;
  --ac-pixel-size-38: 2.375rem;
  --ac-pixel-size-44: 2.75rem;
  --ac-pixel-size-46: 2.875rem;
  --ac-pixel-size-48: 3rem;
  --ac-pixel-size-52: 3.25rem;
  --ac-pixel-size-56: 3.5rem;
  --ac-pixel-size-64: 4rem;
  --ac-pixel-size-99: 6.1875rem;

  /* BREAKPOINTS */
  --ac-breakpoint-desktop-lg-min: 1921px; //998px
  --ac-breakpoint-desktop-md-max: 1920px; //998px
  --ac-breakpoint-desktop-md-min: 1441px; //998px
  --ac-breakpoint-desktop-sm-max: 1440px; //998px
  --ac-breakpoint-desktop-sm-min: 1281px; //998px
  --ac-breakpoint-tablet-max: 1280px; //998px
  --ac-breakpoint-tablet-min: 769px; //998px
  --ac-breakpoint-mobile-max: 768px; //998px
  --ac-breakpoint-mobile-min: 0px; //998px
}
