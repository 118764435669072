@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-button {
  position: relative;
  border: var(--ac-pixel-size-1) solid var(--ac-color-transparent);
  cursor: pointer;
  display: inline-block;
  vertical-align: bottom;
  @include button-transitions();
  @include title-font();

  &-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  // DEFAULT VARIANT

  &-variant-default {
    border-radius: var(--ac-pixel-size-48);
    overflow: hidden;

    &:after {
      transition: opacity 0.4s ease, transform 0.3s ease-in-out;
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      border-radius: 100%;
      display: block;
      width: 140px;
      height: 140px;
      background: var(--ac-color-gradient-button);
      transform: translate(-100%, 60%);
      opacity: 0;
    }
    &:hover {
      &:after {
        transform: translate(50%, 0);
        opacity: 1;
      }
    }
    // DEFAULT PRIMARY

    &-color-primary {
      &-light {
        background-color: var(--ac-color-neutral-00);
        color: var(--ac-color-black);
        fill: var(--ac-color-black);
      }

      &-dark {
        background-color: var(--ac-color-black);
        color: var(--ac-color-neutral-00);
        fill: var(--ac-color-neutral-00);
      }
    }

    &:focus-visible {
      outline: var(--ac-pixel-size-4) solid var(--ac-color-primary);
    }

    // DEFAULT SECONDARY

    &-color-secondary {
      background-color: var(--ac-color-transparent);
      border-color: var(--ac-color-neutral-200);
      color: var(--ac-color-black);
      fill: var(--ac-color-black);

      &-light {
        color: var(--ac-color-neutral-00);
        fill: var(--ac-color-neutral-00);
      }

      &-dark {
        color: var(--ac-color-black);
        fill: var(--ac-color-black);
      }

      &:hover {
        color: var(--ac-color-neutral-00);
        fill: var(--ac-color-neutral-00);
      }

      &:focus-visible {
        outline: var(--ac-pixel-size-4) solid var(--ac-color-secondary-100);
      }
    }

    // DEFAULT WITH ICON

    &--has-icon {
      padding: var(--ac-pixel-size-12) var(--ac-pixel-size-28)
        var(--ac-pixel-size-28) var(--ac-pixel-size-12);

      .ac-button-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--ac-pixel-size-24);
        height: var(--ac-pixel-size-24);
        margin-left: var(--ac-pixel-size-16);
      }
    }

    // DEFAULT DISABLED

    &--disabled {
      background-color: var(--ac-color-neutral-400);
      border-color: var(--ac-color-neutral-400);
      pointer-events: none;
      color: var(--ac-color-neutral-200);
      fill: var(--ac-color-neutral-200);
    }
  }

  // TEXT VARIANT

  &-variant-text {
    border: 0;
    background-color: var(--ac-color-transparent);

    // TEXT PRIMARY

    &-color-primary {
      color: var(--ac-color-primary-500);
      fill: var(--ac-color-primary-500);

      &:hover {
        color: var(--ac-color-primary-700);
        fill: var(--ac-color-primary-700);
      }
    }

    // TEXT WITH ICON

    &--has-icon {
      .ac-button-icon {
        margin-right: var(--ac-pixel-size-10);
      }
    }

    // TEXT DISABLED

    &--disabled {
      pointer-events: none;
      color: var(--ac-color-neutral-400);
      fill: var(--ac-color-neutral-400);
    }
  }

  // ICON VARIANT

  &-variant-icon {
    background-color: var(--ac-color-transparent);
    fill: var(--ac-color-neutral-400);
    padding: 0;

    &-filled {
      box-sizing: border-box;
      border-radius: 50%;
      width: var(--ac-pixel-size-46);
      height: var(--ac-pixel-size-46);
      border: 4px solid transparent;

      background-clip: padding-box;
      outline-offset: -1px;

      &:after {
        content: '';
        transition: border 0.1s ease-in-out;
        position: absolute;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        background-color: transparent;
        border-radius: var(--ac-pixel-size-30);
        border: 1px solid var(--ac-color-neutral-400);
      }

      &-color-primary {
        background-color: var(--ac-color-primary);
        position: relative;

        &-light {
          path {
            fill: var(--ac-color-neutral-00);
          }
        }

        &-dark {
          path {
            fill: var(--ac-color-black);
          }
        }
      }

      &:hover {
        &:after {
          border: 2px solid use-alpha(--ac-color-neutral-00, 0.8);
        }
      }
    }

    // ICON PRIMARY

    &-color-primary {
      padding: 0;
      &:hover,
      &:focus {
        fill: var(--ac-color-primary);
      }

      &:focus-visible {
        outline: none;
      }
    }

    // ICON SECONDARY

    &-color-secondary {
      &:hover,
      &:focus-visible {
        background-color: rgba(77, 102, 99, 0.08);
      }

      &:active {
        background-color: rgba(77, 102, 99, 0.16);
      }

      &:focus-visible {
        outline: none;
      }
    }

    // ICON LIGHT

    &-color-light {
      padding: 0;
      &:hover,
      &:focus {
        fill: var(--ac-color-neutral-00);
      }

      &:focus-visible {
        outline: none;
      }
    }

    // ICON DISABLED

    &--disabled {
      pointer-events: none;
      color: var(--ac-color-neutral-400);
      fill: var(--ac-color-neutral-400);
    }
  }

  // PUSH VARIANT

  &-variant-push {
    border-radius: var(--ac-pixel-size-9);

    // PUSH PRIMARY

    &-color-primary {
      background-color: var(--ac-color-primary-500);
      color: var(--ac-color-neutral-white);
      fill: var(--ac-color-neutral-white);

      &:hover {
        background-color: var(--ac-color-primary-700);
        box-shadow: var(--ac-box-shadow-md);
      }

      &:focus-visible {
        outline: var(--ac-pixel-size-4) solid var(--ac-color-primary-300);
      }
    }

    // PUSH SECONDARY

    &-color-secondary {
      background-color: var(--ac-color-neutral-white);
      color: var(--ac-color-neutral-700);
      fill: var(--ac-color-neutral-700);

      &:hover {
        box-shadow: var(--ac-box-shadow-md);
        color: var(--ac-color-neutral-400);
        fill: var(--ac-color-neutral-400);
      }

      &:focus-visible {
        outline: var(--ac-pixel-size-4) solid var(--ac-color-secondary-100);
      }
    }

    // PUSH WITH ICON

    &--has-icon {
      padding: var(--ac-pixel-size-14) var(--ac-pixel-size-24)
        var(--ac-pixel-size-14) var(--ac-pixel-size-16);

      .ac-button-icon {
        margin-right: var(--ac-pixel-size-10);
      }
    }

    // PUSH DISABLED

    &--disabled {
      background-color: var(--ac-color-neutral-400);
      border-color: var(--ac-color-neutral-400);
      pointer-events: none;
      color: var(--ac-color-neutral-200);
      fill: var(--ac-color-neutral-200);
    }
  }

  &--full-width {
    box-sizing: border-box;
    width: 100%;
  }

  &--padding-none {
    padding: 0;
  }

  &--padding-xs {
    padding: var(--ac-pixel-size-4);
  }

  &--padding-default {
    padding: var(--ac-pixel-size-12) var(--ac-pixel-size-28);
  }

  &--padding-chip {
    padding: 0.375rem 0.75rem;
  }

  &--padding-md {
    padding: 0.75rem;
  }
}
